'use client';
import { Guid } from '@komo-tech/core/models/Guid';
import { useRouter } from 'next/navigation';
import { useLayoutEffect } from 'react';

import { useAdminPortalContext } from '@/admin/components/AdminPortalStore';
import { AdminCache } from '@/admin/data/AdminCache';
import {
  AdminRoutes,
  resolveAdminRoute
} from '@/admin/utils/Routes/AdminRoutes';
import { LoadingPage } from '@/common/components/Pages/LoadingPage';

export default function Redirect() {
  const setWorkspaceAsync = useAdminPortalContext(
    (x) => x.changeWorkspaceAsync
  );
  const workspace = useAdminPortalContext((x) => x.workspace);
  const router = useRouter();
  useLayoutEffect(() => {
    if (workspace) {
      router.push(resolveAdminRoute(AdminRoutes.workspaces.hubs));
    } else {
      setWorkspaceAsync(AdminCache.workspaceId || Guid.newGuid(), true);
    }
  }, []);

  return <LoadingPage variant="komo" />;
}
